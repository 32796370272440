.main-container {

  .search-form {
    .form-group {
      max-width: 300px;
    }

    #edit-advanced {
      .criterion:nth-of-type(2) {
        position: relative;
        left: 30px;
      }
    }
  }
  .search-results {
    list-style: none;
  }
}

.search-results {
  margin: 0;
  padding: 0;
  list-style: none;

  > .search-result {
    float: none;
    margin: 0 0 30px 0;
    padding: 0;
    background: none;

    .search-info {
      display: none;
    }
  }
}
