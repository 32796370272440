.container {

  .view-pager {
    margin-top: 45px;
    text-align: right
  }

  .view-footer {

    .product-category {

      .cat-data {

        h4 {
          a{
            color: black;
            font-size: 16px;
          }
        }

        .cat-product-price {
          font-size: 14px;
        }

        .cat-product-details {
          color: black;
          font-size: 14px;
          background: url("../gfx/details-arrow.png") no-repeat scroll 85px 5px;
          padding-right: 21px
        }

      }
    }
  }
}

.product-listing {
  clear: both;
}
.product-listing__list {
  margin: 0;
  padding: 0;
  list-style: none;

  @media (min-width: $screen-sm-min) {
    margin: 0 15px;
  }
  > li {
    clear: none;
    float: left;
    margin: 0 0 20px 0;
    padding: 0 15px;
    width: 50%;

    &:nth-child(4n+1) {
      clear: none;
    }
    &:nth-child(2n+1) {
      clear: left;
    }

    @media screen and (min-width: $screen-sm-min) {
      width: 25%;

      &:nth-child(2n+1) {
        clear: none;
      }
      &:nth-child(4n+1) {
        clear: left;
      }
    }
  }
}
