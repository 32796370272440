/*
 * Five Star Awards Custom Variables.
 */
$black: #000;
$white: #fff;

$fvstr-main-gray: #1f1f1f;
$fvstr-header-link-gray: #ccc;
$fvstr-light-gray: #999;
$fvstr-lighter-gray: #f0f0f0;
$fvstr-dark-gray: #666;
$fvstr-footer-gray: #333;
$fvstr-footer-gray-bottom: rgba(0,0,0,.4);

$fvstr-red: #ed1c24;
$fvstr-off-white: #eee;

/**
 * Responsive Helpers.
 */
$screen-min: 320px;

// Extra small screen / phone.
$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;

// Small screen / large phone.
$screen-sm-ph: 600px;

// Small screen / tablet.
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;

// Medium screen / desktop.
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;

// Large screen / wide desktop.
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;

// Extra large screen / wider desktop.
// See '_bootstrap-overrides.scss' for details.
$screen-xl: 1560px !default;
$screen-xl-min: $screen-xl !default;

// So media queries don't overlap when required, provide a maximum.
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
