.footer-wrapper {
  width: 100%;
  background: $fvstr-footer-gray;
  position: relative;
  &-bottom {
    position: absolute;
    height: 72px;
    width: 100%;
    bottom: 0;
    background-color: $fvstr-footer-gray-bottom;
  }
}

footer {
  @include reset-mp();
  margin: 0 auto;
  width: 100%;
  background: url('../gfx/footer_bg.png') 10% -150px no-repeat;
  font-family: "Verdana", Arial, Helvetica, sans-serif;

  .menu.nav {
    > li {
      font-size: 24px;
      font-weight: bold;
      > a {
        text-transform: capitalize;
        color: $fvstr-off-white;
        &:focus {
          background-color: transparent;
        }
      }
      .footer-submenu {
        @include reset-mp();
        > li {
          margin-top: 0.5em;
          font-size: 14px;
          list-style-type: none;
          > a {
            font-weight: normal;
            color: $fvstr-off-white;
            &:hover {
              color: $white;
            }
          }
        }
      }
    }
  }
}

.footer-menu {
  margin-top: 20px;
  margin-bottom: 40px;
  & div.col-sm-8 {
    @media screen and (max-width: 995px) {
      display: none;
    }
  }
  .menu.nav {
    > li {
      > a {
       @include reset-mp();
        font-weight: normal;
      }
    }
    @media screen and (max-width: 995px) {
      display: none;
    }
  }
  section p {
    @media screen and (max-width: 995px) {
      text-align: center;
    }
  }
  .navbar-collapse.collapse {
    display: block!important;
  }

  .navbar-nav>li, .navbar-nav {
    float: left !important;
  }

  .navbar-nav.navbar-right:last-child {
    margin-right: -15px !important;
  }

  .navbar-right {
    float: right!important;
  }
}

.footer-bottom {
  position: relative;
  z-index: 9;
  font-size: 11px;
  color: $fvstr-light-gray;
  margin-bottom: 24px;

  .privacy-link {
    color: #ccc;
  }

  div, p {
    @media screen and (max-width: 750px) {
      text-align: center;
      display: block;
    }
  }

  a {
    color: #999;
    &:hover {
    }
  }

  .acro {
    text-align: right;
  }

}

.footer-logo {
  padding-top: 30px;
  text-align: right;
  letter-spacing: 1px;

  .rteright {
    color:  #8b8b8b;

    a {
      text-decoration: none;
      color: #c2c2c2;
    }
  }

  @media screen and (max-width: 995px) {
    text-align: center;
  }
}

.region-footer {
  .block-block.acro {
    font-size: 10px;
  }
}
