.main-container {
  .form-item-name {
    padding-top: 15px;
  }

  #user-login{
    padding-bottom: 100px;
  }

  .btn-success {
    margin: 15px 0px;
  }

  form.user-info-from-cookie {
    padding-bottom: 35px;
  }

  .url-textfield {
    display: none;
  }

  #edit-name {
    margin: 15px 0px;
  }

  #edit-submit {
    margin: 15px 0px;
  }

}

