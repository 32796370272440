/**
 * About Us.
 */
.main-container {
  .region-sidebar-second {
    h2 {
      display: none;
    }
    ul {
      padding-top: 0;

      @media (min-width: $screen-sm-min) {
        padding-top: 50px;
      }
      li {
        display: block;
        font-size: 16px;

        a {
          color: #999999;

          &:hover {
            color: #999999;
            opacity: 0.8;
          }

        }
      }
    }
  }
}
