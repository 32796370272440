.main-container {

  #commerce-checkout-form-checkout {

    #edit-customer-profile-billing-commerce-customer-profile-copy {
      margin-left: 0px;
    }
  }

  .view-commerce-cart-form {
    overflow: auto;
  }

  button#edit-checkout {
    background-color: #00b200;
    color: #fff;

    &:hover {
      opacity: 0.8;
    }
  }

  #commerce-checkout-form-review {
    .commerce-credit-card-expiration {
      .form-select {
        display: inline-block;
        width: auto;
      }
    }
  }
}
