/*General*/
body.maintenance-page {
  padding: 0;
  margin: 0;
  background: #000 url('../gfx/maintenance-bg.jpg') no-repeat center top;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 18px;
  * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  a {
    text-decoration: none;
    -webkit-transition: color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out;
    -ms-transition: color .2s ease-in-out;
    -o-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
  }

  .container {
    width: 850px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 30px;
  }

  /*Logo*/
  .logo {
    margin: 100px auto 0;
  }

  /*Main Text*/
  .main-text-wrapper {
    margin: 0 0 40px 0;
  }

  .main-text-wrapper h1 {
    margin: 0 0 0 0;
    font-size: 28px;
    font-weight: 300;
    color: #fff;
  }

  .main-text-wrapper p {
    margin: 25px 0 40px;
    color: #cccccc;
    font-weight: 300;
  }

  /*Contact Info*/
  .contact-info {
    margin: 0;
    padding: 0;
    color: #ffcc66;
    list-style: none;
    background: none;
  }

  .contact-info li {
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1.2;
  }

  .contact-info li a {
    color: #ffcc66;
  }

  .contact-info li a:hover,
  .contact-info li a:focus {
    color: #C18F2A;
  }

  .contact-info li span {
    font-size: 16px;
    text-transform: uppercase;
    color: #999999;
  }

  /*Copyright*/
  .copyright {
    font-size: 11px;
    color: #333333;
  }

  @media (min-width: 850px) {
    /*Logo*/
    .logo {
      margin: 150px auto 0;
    }

    /*Main Text*/
    .main-text-wrapper {
      margin: 0 0 90px 0;
    }

    .main-text-wrapper h1 {
      font-size: 48px;
    }

    .main-text-wrapper p {
      font-size: 28px;
    }

    /*Contact Info*/
    .contact-info {
      display: table;
      vertical-align: top;
      width: 100%;
      font-size: 28px;
    }

    .contact-info li {
      display: table-cell;
      margin: 0;
    }
  }
}