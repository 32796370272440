.front {
  #homepage-divider {
    height: 63px;
    background: url('../gfx/homepage_divider.png') bottom no-repeat;
  }

  .main-cta-wrapper {
    margin-top: 56px;
    width: 100%;
    background-color: #131311;
    @media screen and (max-width: 768px) {
      margin-top: 5px;
    }
  }

  .page-header.front {
    border: none;
    text-align: center;
    padding-bottom: 0px;
  }

  .main-container {
    padding-top: 0;
    padding-bottom: 50px;

    section {
      padding-bottom: 40px;
    }
    section.block-system {
      clear: both;
      padding-bottom: 0;
    }

    .region {
      padding-top: 17px;

      .node-page{
        padding-top: 15px;
      }

    }

    .featured-products-title h3{
      padding-left: 40px;
      color: #666666;
    }

    .featured-products {

      .cat-data{
        width: 300%;
        position: relative;
        left: -15px;
        text-align: left;
        width: 160px !important;
      }

      a {
        text-decoration: none;
        color: #333333;

        &:hover {
          opacity: 0.8;
        }
      }

      h4 {
        font-size: 14px;
        color: black;
        margin-bottom: 5px;
      }

      .cat-product-price {
        padding-bottom: 10px;
      }

      .cat-product-details {
        background: url("../gfx/details-arrow.png") no-repeat scroll 85px 5px;
        padding-right: 15px;
      }

      .flex-control-nav {
        display: none;
      }

      .flex-direction-nav {
        display: none;
      }

      .flex-viewport {
        height: 260px;

        img {
          margin-bottom: 15px;
        }

        li {

          .product-category {


            .cat-image {
              border: 1px solid #cccccc;
              height: 165px;
              width: 165px;
              text-align: center;
            }
            .cat-data {
              text-align: left;

              .field-item {
                text-align: left;
              }
            }
          }

          .cat-product-price {
            font-size: 13px;
            width: auto;
            text-align: left !important;
          }

          .view-details {
            position: relative;
            bottom: -230px;
            left: -60px;
          }
        }
      }
    }
  }

  .custom-cta {
    margin-bottom: 50px;
    color: #000;
    .faq, .custom-work {
      height: 265px;

      h2 {
        @include reset-mp();
        padding-top: 30px;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 10px;

        @media screen and (max-width: 550px) {
          font-size: 24px;
        }
        @media screen and (max-width: 400px) {
          font-size: 22px;
        }
      }

      a {
        background: #000000;
        padding: 8px 30px;
        font-weight: bold;
        color: #fff;
        text-decoration: none;
        &:hover {
          opacity: 0.85;
        }
      }

      .col-xs-15,
      .col-sm-15,
      .col-md-15,
      .col-lg-15 {
        position: relative;
        min-height: 1px;
        padding-right: 10px;
        padding-left: 10px;
      }

      .col-xs-15 {
        width: 20%;
        float: left;
      }

      @media (min-width: 768px) {
        .col-sm-15 {
          width: 20%;
          float: left;
        }
      }

      @media (min-width: 992px) {
        .col-md-15 {
          width: 20%;
          float: left;
        }
      }

      @media (min-width: 1200px) {
        .col-lg-15 {
          width: 20%;
          float: left;
        }
      }
    }

    .faq {
      background: url('../gfx/faq-bg.png') no-repeat scroll center top transparent;
      @media screen and (max-width: 1200px) {
        margin-bottom: 35px;
      }

      p {
        text-align: left;
        float: left;
        width: 50%;
        padding-left: 65px;
        padding-bottom: 15px;
        @media screen and (max-width: 1200px) {
          padding-left: 258px;
        }
        @media screen and (max-width: 991px) {
          padding-left: 150px;
        }
        @media screen and (max-width: 766px) {
          padding-left: 104px;
        }
        @media screen and (max-width: 650px) {
          padding-left: 75px;
        }
        @media screen and (max-width: 500px) {
          padding-left: 30px;
        }
        @media screen and (max-width: 400px) {
          width: 90%;
          text-align: center;
        }
      }

      ul {
        float: right;
        padding: 10px 90px 0 0;
        @media screen and (max-width: 1200px) {
          padding-right: 255px;
        }
        @media screen and (max-width: 991px) {
          padding-right: 150px;
        }
        @media screen and (max-width: 766px) {
          padding-right: 140px;
        }
        @media screen and (max-width: 650px) {
          padding-right: 75px;
        }
        @media screen and (max-width: 530px) {
          padding-right: 30px;
        }
        @media screen and (max-width: 400px) {
          display: none;
        }

        li {
          padding: 0 0 0 15px;
          line-height: 18px;
          background: url('../gfx/bul_arrow.png') 0 4px no-repeat;
          list-style: none;
          text-align: left;
        }
      }
    }

    .custom-work {
      background: url("../gfx/custom_bg.jpg") no-repeat scroll center top transparent;
      p {
        text-align: center;
        margin-bottom: 15px;
      }
    }
  }

  .endorsement {
    height: 413px;
    background: url("../gfx/soccer-bg-v2.jpg") center 0px #0a0a0b no-repeat;
    color: $white;
    font-size: 1.3em;
    padding-top: 15px;

    @media screen and (max-width: 990px) {
      height: 715px;
      background-position-x: -275px;
    }

    @media screen and (max-width: 645px) {
      height: 800px;
    }

    @media screen and (max-width: 450px) {
      height: 850px;
      font-size: 16px;
    }

    h2 {
      font-size: 1.6em;
      margin-bottom: 25px;

      @media screen and (max-width: 450px) {
        font-size: 24px;
      }
    }

    .container {
      .col-sm-2 {
        margin-right: 15px;
      }
    }
    a {
      color: $white;
    }
    .why-us {
      @media screen and (max-width: 990px) {
        padding-bottom: 25px;
      }
    }

    .testimonials {
      .views-field-field-testimonial-author {
        margin: 1em 0;
        font-weight: bold;
      }
      .more-link {
        margin: 2em 0;
      }
    }
  }

  .logos {
    padding-top: 50px;
    padding-bottom: 70px;

    @media screen and (max-width: 400px) {
      padding-top: 25px;
      padding-bottom: 35px;
    }

    h1 {
      text-align: center;
      padding-bottom: 35px;
    }
  }

    .partners-wrapper {
      overflow: hidden;
      display: table;
      margin-left: auto;

      div {
        padding-right: 10px;
      }

      ol {
        display: none;
      }

      li {
        list-style: none;
      }

    }

    .view-content {
    }

  }

  .contact-info {
    height: 60px;
    background-color: $fvstr-lighter-gray;
    font-size: 24px;
    color: $fvstr-dark-gray;
    line-height: 60px;
    font-weight: lighter;
    @media screen and (max-width: 1200px) {
      font-size: 20px;
    }
    @media screen and (max-width: 995px) {
      font-size: 16px;
    }
    @media screen and (max-width: 770px) {
      font-size: 16px;
      height: 180px;
    }
    a {
      color: $fvstr-dark-gray;
      &:hover {
        color: $black;
        text-decoration: none;
      }

    }
    &-icon {
      margin-left: 5px;
      height: 32px;
      width: 32px;
      display: inline-block;
      position: relative;
      top: 6px;
      &:hover {
        cursor: pointer;
      }
    }
    &-social {
      text-align: left;

      &-icons {
        display: inline-block;
        &--linkedin {
          @include sprite-sheet(0, 18px);
          &:hover {
            @include sprite-sheet(0, 50px);
          }
        }
        &--facebook {
          @include sprite-sheet(32px, 18px);
          &:hover {
            @include sprite-sheet(32px, 50px);
          }
        }
      }
    }
    &-phone {
      text-align: center;
      &-icon {
        @include sprite-sheet(64px, 18px);
        &:hover {
          @include sprite-sheet(64px, 50px);
        }
      }
    }
    &-email {
      text-align: right;
      @media screen and (max-width: 770px) {
        text-align: center;
      }
      &-icon {
        @include sprite-sheet(96px, 18px);
        &:hover {
          @include sprite-sheet(96px, 50px);
        }
      }
    }
  }

  .sign-up {
    @include placeholder-color($white);
    margin: 2px 0;
    padding: 2px 0;
    height: 60px;
    background-color: $fvstr-dark-gray;
    color: $white;
    text-align: center;

    @media screen and (max-width: 995px){
      height: 150px;
    }

    h2 {
      font-size: 24px;
      font-weight: lighter;
      display: inline;
      line-height: 60px;

      @media screen and (max-width: 995px) {
        font-size: 22px;
        display: block;
      }

      @media screen and (max-width: 400px) {
        font-size: 20px;
      }

      @media screen and (max-width: 350px) {
        font-size: 18px;
      }

    }

    form {
      display: inline;

      div {
        display: inline;
      }

      input, button {
        padding: 7px 10px;
        font-size: 18px;
        color: $white;
        border: none;
        border-radius: 0;
        vertical-align: super;

        @media screen and (max-width: 400px) {
          font-size: 16px;
        }

        @media screen and (max-width: 350px) {
          font-size: 14px;
        }

      }

      input {
        display: inline-block;
        margin: 0 10px 0 20px;
        text-align: right;
        background-color: $fvstr-light-gray;

        @media screen and (max-width: 450px) {
          margin: 0px;
          padding: 10px 7px;
          font-size: 16px;
        }

        @media screen and (max-width: 350px) {
          padding: 7px 1px;
        }
        width: auto;
      }

      button {
        background-color: $fvstr-footer-gray;
        font-weight: bold;
        &:hover {
          opacity: 0.8;
        }
      }

    }
  }

