@mixin sprite-sheet($x, $y) {
  background: url("../gfx/spritesheet.png") (-$x) (-$y) no-repeat;
}

@mixin reset-mp() {
  margin: 0;
  padding: 0;
}

@mixin chrome-transition-shake() {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1.0, 1.0);
}

@mixin placeholder-color($color) {

  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    $color;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    $color;
    opacity:  1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    $color;
    opacity:  1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:    $color;
  }
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* Fallback. */
  background: rgba($color, $opacity);
}
