
.site-header {
  margin: 0;
  padding: 0;
  min-height: 110px;
  background: #1f1f1f;
}

.site-header-logo {
  position: relative;
  top: 0;
  left: 0;
  margin: 10px auto;
  width: 146px;
  height: auto;
  z-index: 2;

  @media (min-width: $screen-xs-min) {
    position: absolute;
    top: 0;
    left: 15px;
    margin: 0;
    width: 201px;
    height: auto;
  }
  @media (min-width: $screen-sm-min) {
    width: 185px;
  }
  @media (min-width: $screen-md-min) {
    width: 217px;
  }

  a {
    display: block;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}
.site-header-logo-glow {
  position: absolute;
  display: none;
  top: 0;
  left: -160px;
  width: 550px;
  height: 110px;
  background: url(../gfx/logo_glow_bg.png) 0 0 no-repeat;
  z-index: 0;

  @media (min-width: $screen-xs-min) {
    display: block;
  }
}

.site-header-content {
  position: relative;
  margin: 0;
  padding: 0;
  z-index: 1;

  @media (min-width: $screen-sm-min) {
    padding: 0 0 0 195px;
  }
  @media (min-width: $screen-md-min) {
    padding: 0 0 0 249px;
  }

}

.site-header-top {
  clear: both;
  margin: 0 0 19px 0;
  padding: 0;
  text-align: center;

  @media (min-width: $screen-xs-min) {
    text-align: right;
  }
}
.site-header-nav-wrapper {
  display: inline-block;
  vertical-align: top;

  ul.menu {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    @media (min-width: $screen-xs-min) {
      text-align: right;
    }

    li {
      display: inline-block;
      float: none;
      margin: 0 10px 0 0;
      padding: 0;
      vertical-align: top;

      @media (min-width: $screen-xs-min) {
        padding: 24px 0 0 0;
      }
      @media (min-width: $screen-md-min) {
        margin: 0 25px 0 0;
      }

      a {
        display: block;
        margin: 0;
        padding: 0;
        color: #ccc;
        font-size: 12px;
        text-decoration: none;
        text-transform: uppercase;

        @media (min-width: $screen-md-min) {
          font-size: 14px;
        }

        &:hover {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}
.site-header-nav {
  display: none;

  @media (min-width: $screen-sm-min) {
    display: block;
  }
}
.site-header-mobile-nav {
  display: block;

  @media (min-width: $screen-sm-min) {
    display: none;
  }
}


.site-header-cart {
  display: inline-block;
  vertical-align: top;

  ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    @media (min-width: $screen-xs-min) {
      text-align: right;
    }

    li {
      display: inline-block;
      margin: 0 7px 0 0;
      padding: 0;
      background: none;
      vertical-align: top;

      &.li-site-header-cart {
        display: none;
        padding: 0;

        @media (min-width: $screen-xs-min) {
          padding: 24px 0 0 0;
        }
        @media (min-width: $screen-md-min) {
          display: inline-block;
        }

        a {
          display: block;
          margin: 0;
          padding: 0;
          color: #ccc;
          font-size: 12px;
          text-decoration: none;
          text-transform: uppercase;

          @media (min-width: $screen-md-min) {
            font-size: 14px;
          }

          &:hover {
            color: #fff;
            text-decoration: none;
          }
        }
      }
      // Header cart icon item.
      &.li-site-header-cart-icon {
        padding: 0;

        @media (min-width: $screen-xs-min) {
          padding: 21px 0 0 0;
        }

        a {
          position: relative;
          display: block;
          margin: 0;
          padding: 0 28px 0 10px;
          height: 22px;
          min-width: 57px;
          color: #fff;
          font-size: 14px;
          line-height: 22px;
          text-decoration: none;
          text-align: center;
          border-radius: 9px;
          background-color: #ed1c24;
          vertical-align: middle;

          &:after {
            content: '';
            position: absolute;
            top: 1px;
            right: 2px;
            display: block;
            margin: 0;
            padding: 0;
            width: 20px;
            height: 20px;
            background: url(../gfx/ico_cart.png) 0 0 no-repeat;
          }

          &:hover {
            background: #db1a21;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}


.site-header-bottom {
  clear: both;
  display: none;
  text-align: right;

  @media (min-width: $screen-xs-min) {
    display: block;
  }
}
.site-header-contact-info {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: top;

  @media (min-width: $screen-sm-min) {
    margin: 0 25px 0 0;
  }

  a {
    font-size: 12px;
    color: #999;
    text-decoration: none;

    @media (min-width: $screen-sm-min) {
      font-size: 14px;
    }

    strong {
      font-size: 16px;
      color: #ccc;
      text-decoration: none;
      font-weight: normal;

      @media (min-width: $screen-sm-min) {
        font-size: 18px;
      }
    }
    &:hover {
      strong {
        color: #fff;
      }
    }
  }
}

/* Site Header Search. */
.site-header-search {
  position: relative;
  display: none;
  margin: 0;
  padding: 0 27px 0 0;
  width: 105px;
  vertical-align: top;
  transition: width .4s ease-in-out;

  @media (min-width: $screen-sm-min) {
    display: inline-block;
  }

  // When search is active/on focus.
  &.search-active {
    width: 200px;
  }

  form {
    margin: 0;
    padding: 0;
  }
  .input-group {
    display: block;
    .form-control {
      float: none;
    }
  }
  h2 {
    display: none;
  }
  .form-text {
    display: block;
    margin: 0;
    padding: 0 8px;
    width: 100%;
    height: auto;
    line-height: auto;
    font-size: 18px;
    text-align: right;
    color: #666;
    background: none;
    border: none;
    outline: none;
  }
  .form-submit,
  button.btn,
  .input-group-btn {
    display: none;
  }
}
.b-site-header-search {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: #807e7e;
  text-decoration: none;
  outline: none;

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  .glyphicon {

  }
}











// Mobile overlay.
.mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  height: 100%;
  width: 100%;
  @include background-opacity(#fff, 1);
  z-index: 9999;
  overflow-x: hidden;
}

// Mobile Search Toggle.
.b-mobile-search-toggle {
  float: right;
  display: block;
  margin: 9px 12px 0 0;
  padding: 2px 5px;
  font-size: 23px;
  color: #ccc;

  @media (min-width: $screen-sm-min) {
    display: none;
  }

  &:hover {
    color: #fff;
  }
}

// Mobile search.
.mobile-search-overlay__content {
  margin: 0;
  padding: 59px 15px 0 15px;
  height: 110px;
  background: #1f1f1f;
}
.mobile-search-overlay__close {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  margin: 0;
  padding: 10px 15px 0 15px;
  font-size: 24px;
  line-height: 24px;
  color: #807e7e;
  text-decoration: none;
  vertical-align: top;
  outline: none;
  z-index: 10;

  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
  }
  span {
    vertical-align: top;
  }
}
.mobile-search-form {
  position: relative;
  margin: 0;
  padding: 0 34px 5px 0;
  border-bottom: 2px solid #353535;

  .input-group {
    display: block;
  }
  button {
    display: none;
  }
  .block-search .form-search-submit-trigger {
    display: none;
  }
  .form-item,
  .form-actions {
    margin-top: 0;
    margin-bottom: 0;
  }
  label,
  .form-submit,
  .form-optional,
  .search-block-form .form-search-submit-trigger {
    display: none;
  }
  .container-inline div,
  .container-inline label {
    display: block;
  }
  .form-text {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
    height: auto;
    font-size: 22px;
    color: #fff;
    text-align: left;
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
  }
  .form-text:focus {
    background: none;
    outline: none;
    box-shadow: none;
  }
}
.mobile-search-form__submit {
  position: absolute;
  bottom: 11px;
  right: 0;
  font-size: 23px;
  line-height: 23px;
  color: #ccc;
  text-decoration: none;
  vertical-align: top;

  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
  }
  span {
    vertical-align: top;
  }
}
































.navbar-default {
  @include reset-mp();
  background-color: $fvstr-main-gray;
  border: none;
  border-radius: 0;
  overflow: hidden;

  .navbar-header {
    width: 100%;
    position: relative;

    ul a {
      @media screen and (max-width: 990px) {
        font-size: 10px;
      }

      @media screen and (max-width: 800px) {
        font-size: 10px;
      }
    }

    .logo-wrapper {
      background: url('../gfx/logo_glow_bg.png') -25px 0 no-repeat;
      position: absolute;
      top: 0px;
      width: 540px;
      height: 309px;
      left: 0px;
      @media screen and (max-width: 768px) {
      }
    }

    .logo {
      @include reset-mp();
      position: relative;
      z-index: 2;
      top: 1px;
      padding-top: 30px;
      padding-left: 30px;

      @media screen and (max-width: 980px) {
        padding-left: 10px;
      }

      @media screen and (max-width: 780px) {
        padding-left: 45px;
        padding-bottom: 10px;
      }

      img {
        @media screen and (max-width: 980px) {
          width: 180px;
          height: auto;
        }

        @media screen and (max-width: 380px) {
          width: 160px;
        }
      }
    }

    .header-menu {
      float: right;
    }


  }

  .container {
    height: 110px;
    .region-header {
      ul:first-of-type {
        margin-right: 35px;
        margin-top: 5px;
      }
    }
  }
}

.menu.nav {
  padding-top: 0px;
  > li {
    display: inline-block;
    > a {
      text-transform: uppercase;
      color: $fvstr-header-link-gray;
      @include reset-mp();
      margin: 15px 15px 10px 15px;
      &:hover {
        color: $white;
        background: none;
      }
    }
  }
}

.main-nav .menu.nav .dropdown-menu {
  background-color: #000;
  position: absolute;
  z-index: 10;
  top: 46px;
  .li{
    text-decoration: none;
  }
}

.navbar-collapse {
  @media screen and (max-width: 1000px) {
    padding: 0px;
  }
}

#navbar-menu {
  .header-menu .nav {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .menu-mobile-login {
    display: none;

    @media screen and (max-width: 768px) {
      display: inline-block;
      position: relative;
      top: 25px;

      .first {
        display: none;
      }
    }

    @media screen and (max-width: 470px) {
      display: block;
      top: 60px;
      right: -70px;
    }

    @media screen and (max-width: 360px) {
      top: 73px;
    }
  }



}

.nav {
  .columns {
    height: 220px;
    -webkit-column-count: 6;
    -moz-column-count: 6;
    column-count: 6;
    padding: 15px 5px;

    @media screen and (max-width: 1200px) {
      height: 240px;
      -webkit-column-count: 5;
      -moz-column-count: 5;
      column-count: 5;
      padding: 15px 5px;
    }

    @media screen and (max-width: 995px) {
      height: 300px;
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
      padding: 15px 5px
    }

    @media screen and (max-width: 768px) {
      height: auto;
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
      padding: 5px;
    }
  }
}

.main-nav {
  height: 50px;
  width: 100%;
  background-color: $black;
  .container .menu.nav {
    float: right;
    @media screen and (max-width: 1200px) {
      float: none;
    }
    > li {
      &:hover {
        margin: 0px;
        padding: 0px;
        background-color: $fvstr-footer-gray;
      }
      > a {
        font-size: 17px;
        color: #d3d3d3;
        padding: 13px 15px;
        margin: 0px;
        @media screen and (max-width: 1200px) {
          padding: 13px 6px;
          font-size: 15px;
        }
        @media screen and (max-width: 1000px) {
          padding: 13px 4px;
          font-size: 14px;
        }
        @media screen and (max-width: 990px) {
          padding: 13px 4px;
          font-size: 12px;
        }
        @media screen and (max-width: 860px) {
          padding: 13px 2px;
          font-size: 12px;
        }
        &:hover,
        &:focus {
          background-color: $fvstr-footer-gray;
          color: #fff;
        }
      }
    }
    ul {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
  .navbar-toggle {
    border: 1px solid;
    background-color: #333;
  }
  .navbar-toggle .icon-bar {
    background-color: #fff;
  }
  .nav li.open {
    background-color: $fvstr-footer-gray;
  }
  .nav .open>a {
    background-color: $fvstr-footer-gray;
  }
  .dropdown-menu li a {
    color: #d3d3d3;
    font-weight: bold;
    word-wrap: normal;

    &:hover,
    &:focus {
      background-color: $fvstr-footer-gray;
      color: #fff;
    }
  }
}

@media (max-width: 1200px) {
  .navbar-header .menu.nav>li>a {
    margin: 15px 10px 10px 0px;
  }
}
@media (max-width: 768px) {
  .main-nav .menu.nav {
    float: left;
    position: relative;
    z-index: 10;
    background-color: black;
    .dropdown-menu {
      position: relative;
      top: 0px;
    }
    >li{
      display: inline-block;
    }
  }
  .dropdown-menu {
    position: relative;
    float: none;
  }
}

/* Mobile Nav Menu*/
#mobile-side-menu {
  background-color: black;
  width: 240px;
  position: absolute;
  z-index: 10;
  right: 0px;

  @media screen and (min-width: 768px) {
    display: none;
  }

  .navmenu {
    ul.nav {
      display: block;
      float: none;
    }
  }

  li {
    display: block;
    padding-left: 5px;
  }

  .menu {
    .dropdown-menu {
      position: relative;
      top: 0px;
      float: none;
      z-index: 991;
      .nav ul {
        display: block;
      }
    }

    .last {
    }

    ul {
    }
  }

}
