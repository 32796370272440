/**
 * Product.
 */
.main-container {
  overflow: hidden;
}

.page-header.page-header--product {
  display: block;
  border: none;

  @media (min-width: $screen-sm-min) {
    display: none;
  }
}

.page-header.page-header--node-product {
  display: none;
  margin: 0 0 15px 0;
  padding: 0;
  font-size: 38px;
  line-height: 1.2;
  border: none;

  @media (min-width: $screen-sm-min) {
    display: block;
  }
}

.product-container {
  margin-left: auto;
  margin-right: auto;

  li {
    list-style: none;
  }

  #product-images {
    position: relative;
    margin-bottom: 60px;
  }
  .product-details {
    display: inline;
    float: left;

    .label-product-price {
      display: inline-block;
      margin: 0 13px 0 0;
      padding: 0;
      float: none;
      font-size: 28px;
      vertical-align: top;
    }
    .label-product-model {
      display: inline-block;
      float: none;
      padding-top: 16px;
      font-size: 12px;
      color: #666;
      vertical-align: top;
    }
    .product-body-details {
      clear: both;
      margin: 0 0 20px 0;
      padding: 0;
    }
    .price-label {
      display: none;
    }
    td.price-amount {
      border: none;
    }
    .price-amount {
      margin: 0;
      padding: 0;
      background-color: #fff;
      font-size: 28px;
      color: #ef3a41;
    }
    .form-control {
      width: 100%;
    }
  }
  .commerce-add-to-cart {
    position: relative;
    top: 0;
    left: 0;
    padding-top: 20px;

    @media (min-width: $screen-sm-min) {
      top: -70px;
      left: -85px;
    }
  }

  #product-images-nav-cont {
    position: relative;
    clear: both;
    height: 45px;
    max-width: 275px;
    overflow: hidden;
    padding-left: 25px;
    bottom: -25px;

    img {
      position: relative;
      z-index: 5;
    }
    .flex-direction-nav {
      position: relative;
      top: -55px;
    }
    .flex-direction-nav a {
      top: 31%;
      text-indent: 9999px;
      height: 54px;
      width: 19px;
      overflow: hidden;
    }
    li {
      float: none;
    }
    .flex-direction-nav a.flex-prev {
      position: absolute;
      left: 0;
      background: transparent url("../gfx/b_image_gallery_nav_prev.png") no-repeat scroll 0px 6px;
    }
    .flex-direction-nav a.flex-next {
      position: absolute;
      right: 0;
      background: transparent url("../gfx/b_image_gallery_nav_next.png") no-repeat scroll 0px 6px;
      z-index: 4;
    }
    .flex-direction-nav a.flex-prev:hover {
      position: absolute;
      left: 0;
      background: transparent url("../gfx/b_image_gallery_nav_prev.png") no-repeat scroll 0% -34px;
    }
    .flex-direction-nav a.flex-next:hover {
      position: absolute;
      right: 0;
      background: transparent url("../gfx/b_image_gallery_nav_next.png") no-repeat scroll 0% -34px;
    }
  }
  .product-personalization {
    clear: both;
    display: block;
    padding-top: 0;
    padding-left: 0;
    width: 100%;
    font-size: 13px;

    @media (min-width: $screen-sm-min) {
      padding-top: 60px;
      padding-left: 45px;
    }
    label {
      margin-top: 15px;
      display: block;
    }
    .form-submit {
      font-size: 18px;
    }
    .attributes-product-select label {
      margin-top: 50px;
    }
    .personalization-container {
      > ul {
        margin: 0;
        padding: 0;

        @media (min-width: $screen-sm-min) {
          padding: 0 0 0 40px;
        }
      }
      button[value='quantity'] {
        visibility: hidden;
        position: absolute;;
      }
    }
    #edit-quantity-next-step {
      position: relative;
      top: 43px;
      float: right;
      right: 4px;
      font-size: 13px;
      height: 32px;
    }
    select.form-control {
      width: 30%;
    }
    .form-item-attributes-field-product-size {
      label {
        display: inline;
        padding-right: 85px;
      }
    }
    .form-item-attributes-field-color {
      label {
        display: inline;
        padding-right: 75px;
      }
    }
    .form-item-attributes-field-ribbon-color {
      label {
        display: inline;
        padding-right: 30px;
      }
    }
    .form-item-attributes-product-select {
      label {
        display: inline;
        padding-right: 12px;
      }
    }
    select[name="attributes[field_product_size]"] {
      width: 20%;
      float: none;
      display: inline;
      min-width: 80px;
    }
    select[name="attributes[field_ribbon_color]"] {
      width: 30%;
      float: none;
      display: inline;
    }
    select[name="attributes[product_select]"] {
      width: 80%;
      max-width: 300px;
      float: none;
      display: inline;
    }
    select[name="attributes[field_color]"] {
      width: 30%;
      float: none;
      display: inline;
    }
    .quantity {
      label {
        display: inline;
        padding-right: 55px;
      }
      input {
        display: inline;
      }
      .form-control {
        width: 58px;
      }
    }
    .personalize {
      position: relative;
        .form-radios {
          div:nth-child(1) {
            label {
              color: #3399ff;
              font-size: 18px;
              background: url('../gfx/spritesheet.png') -20px -111px no-repeat;
              padding-left: 30px;
            }
          }
        }
      .form-type-radio:second-child label {
        padding-top: 5px;
      }
      label {
        color: #ef3a41;
        display: inline;
        float: left;
        padding-right: 10px;
      }
      input {
        display: none;
      }
      .hide {
        display: none;
      }
    }
    #edit-qty-submit {
      position: absolute;
    }
    .step-one {

    }
    .step-two {
      label {
        float: none;

        &.quantity + div {
          padding-top: 15px;
        }
      }
      .form-group {
        padding-top: 15px;
      }
    }
    #custom-engraving-container {
      .field-multiple-table {
        thead {
          display: none;
        }
        h3 {
          margin-top: 0px;
          font-size: 18px;
          font-weight: bold;
          color: #666666;
        }
      }
      .form-control {
        width: 75%;
        float: left;
        display: inline;
        margin-bottom: 7px;
      }
      table tbody tr td {
        border: none;
        padding-bottom: 15px;
      }
    }
    .form-group {
      margin-bottom: 15px;
      clear: both;
    }
    .a.btn {
      float: right;
      padding-top: 0px;
    }
    .draggable {
      background-color: #fff;
    }
    #custom-engraving-container {
      font-size: 13px;
      max-height: 445px;
      max-width: 530px;
      overflow-y: auto;
      overflow-x: visible;
      width: 558px;

      .field-multiple-table a {

      }
      .hide {
        display: none;
      }
      .form-group {
        margin-bottom: 0;
      }
      .tabledrag-toggle-weight-wrapper {
        display: none;
      }
    }
    #custom-engraving-container .btn {
      color: #444;
      padding: 3px 5px;
      margin-left: 5px;
      margin-top: 4px;
      font-size: 13px;
      border: 1px solid #c5c5c5;
      background: linear-gradient(to bottom, #ffffff 0%, #f5f5f5 100%);
    }
    .form-control.active {
      border-color: #ccc;
    }
    .form-control.inactive {
      border-color: #eee;
    }
    .btn-danger {
      display: none;
    }
    .field-add-more-submit {
      display: none;
    }
    .field-multiple-drag {
     display: none;
    }
    .field-type-text {
      label {
        display: none;
      }
    }
    .tabledrag-handle {
      display: none;
    }
    .form-type-radios {
      margin-top: 10px;
    }
    .field-type-image {
      margin-bottom: 25px;
      padding-top: 30px;

      .image-widget-data {
        input {
          height: 65%;
          padding: 5px 12px;
        }
       .btn-primary {
         color: #fff;
         background-color: #5bc0de;
         border-color: #46b8da;
        }
      }
    }
  }
}

#commerce-shipping-service-ajax-wrapper .panel-body {
  padding-left: 30px;
}

#edit-commerce-payment .panel-body {
  padding-left: 30px;
}

.view-catalog-taxonomy {
  .product-category {
    text-align: center;

    @media (min-width: $screen-sm-min) {
      text-align: left;
    }
    .cat-data {
      font-size: 18px;
      width: auto;
    }
    .cat-image img,
    .cat-image .img-responsive {
      margin: 0 auto;

      @media (min-width: $screen-sm-min) {
        margin: 0;
      }
    }
  }
   > .view-content {
      display: none; // hide primary listing.
      padding-top: 30px;

     @media screen and (max-width: 480px) {
       padding-left: 20px;
     }
    .views-row {
      padding-bottom: 15px;
      font-size: 18px;
    }
     .view-content {

     }
  }
}

.panel-default>.panel-heading {
  position: relative;
  top: 22px;
  border-top: 1px solid #ddd;
}

.commerce_shipping {
  .panel-body {
    .img-responsive {
      display: inline-block;
    }
  }
}
