body {
  font-family: "Oxygen", Arial, Helvetica, sans-serif;
  min-width: 320px;
}

h1 {
  font-size: 2.1em;
  color: $fvstr-dark-gray;
}

.main-container {
  padding-bottom: 50px;
  min-height: 544px;
}
.not-front .main-container {
  padding-top: 20px;
  padding-bottom: 70px;

  @media (min-width: $screen-sm-min) {
    padding-top: 70px;
  }
}
.page-taxonomy  .main-container {
  padding-top: 0;
}

.breadcrumb-wrapper {
  display: none;
}
.page-taxonomy .breadcrumb-wrapper {
  display: block;
}
.page-header {
  margin-top: 20px;

  @media (min-width: $screen-sm-min) {
    margin-top: 40px;
  }
}
