.main-cta {
  @include reset-mp();
  margin: 0 auto;
  max-width: 1600px;
  .richmedia-banners {
    position: relative;
    .flex-control-nav {
      @include reset-mp();
      position: absolute;
      margin: auto;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      text-align: center;
      > li {
        display: inline-block;
        > a {
          display: inline-block;
          margin: 0 8px;
          background: $fvstr-light-gray;
          height: 6px;
          width: 50px;
          text-indent: -99999px;
          cursor: pointer;
          &.flex-active {
            background: $white;
          }
          &:hover {
            background: $fvstr-dark-gray;
          }
        }

      }
    }
  }
  .slides {
    @include reset-mp();
    background-color: $fvstr-header-link-gray;
    > li > a > img {
      width: 100%;
      max-width: 1600px;
    }
  }
}
